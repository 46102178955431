<template>
  <div>
    <article class="creator-about">
      <div class="creator-about-close-button"> <router-link to="/"><i class="si-cross"></i></router-link></div>

      <div class="creator-image" :style="image"></div>
      <div class="creator-hero">
        <h1 class="creator-name">{{item.name}}</h1>
        <h2 class="creator-minor-name">{{item.subname}}</h2>

        <div class="creator-social">

          <a v-if="item.fb" target="_blank" :href="item.fb"><i class="si-facebook"></i></a>
          <a v-if="item.tw" target="_blank" :href="item.tw"><i class="si-twitter"></i></a>
          <a v-if="item.ig" target="_blank" :href="item.ig"><i class="si-instagram"></i></a>
          <a v-if="item.yt" target="_blank" :href="item.yt"><i class="si-youtube"></i></a>
          <a v-if="item.web" target="_blank" :href="item.web"><i class="si-globe"></i></a>

        </div>
      </div>

      <p>
        {{item.description}}
      </p>
    </article>

    <footer>
      <p>COPYRIGHT © 2019 The Zero Publishing. ALL RIGHTS RESERVED.</p>
    </footer>
  </div>
</template>

<script>
  export default {
    data() {
      return this.store
    },

    computed: {
      item() {
        const {category, name} = this.$route.params
        const creator = this.store.creators
          .find(x => x.name === name && x.category === category)

        if (!creator) return {}

        return creator
      },

      image() {
        if (!this.item) return ''

        return {
          'background-image': `url(${this.item.image})`
        }
      }
    }
  }
</script>
