import 'whatwg-fetch'

import Vue from 'vue'
import VueRouter from 'vue-router'

import Landing from './Landing.vue'
import Detail from './Detail.vue'

Vue.use(VueRouter)

Vue.prototype.store = Vue.observable({
	creators: [],
})

const router = new VueRouter({
	routes: [
		{path: '/', component: Landing},
		{path: '/:category/:name', component: Detail},
	]
})

const app = new Vue({router}).$mount('#app')

async function fetchSpeakers() {
	try {
		const body = await fetch('sample-data.json')
		const data = await body.json()

		app.store.creators = data
	} catch (err) {
		console.warn('Cannot fetch the speakers list at the moment.')
	}
}

fetchSpeakers()
