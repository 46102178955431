<template>
  <div>
    <header>

      <div class="video-background">


        <h1 class="header-title"><img src="../logo.png" height="90px"></img></h1>
        <p class="header-description">นับเป็นครั้งแรกที่ทีมงาน RAiNMaker ได้รวบรวมรายชื่อ 100 ท่านที่มีผลงานโดดเด่นในปี 2019 หรือ iCreator 100 เพื่อต้องการชื่นชมและยกย่องให้ความสามารถที่พวกเขาได้ผลิตคอนเทนต์ดีๆ บนโลกออนไลน์</p>
        <a  style="display:none;" href="https://web.facebook.com/rainmakerth/videos/1384417541731208/?__xts__%5B0%5D=68.ARAghEyX4ZDuL3J7J-BwQA_5fJ6HTNJT2QGEEYc-ciAkzMtAEdZtUuXiM7GzMimezyMiO2cVdOATfBXZcCKKj7UJR42zeQDX_f_ETsFd85ncTnkzPW_pyXP18AmkhDQBa72T7ta_KDJ-CgE9C2Ls25_BKF5PNoekSNH4DDsDn7typH3NLgvD4rW6CoXdc4syxlvT7-CDPuXkwZwL4flEWMogqfHuMu-Ur8Phv_02cERiAAAgQZXb26LTQg0Mi3lcCk_9CPtxuRuCba3s0NxIHtwisoJ4ck2-YUMfRnFR3HHjwdzSiOoUrJC0N25SMsbvYN21Xd0sxGCq1PDCAuqshE4QUh4y-NRq&__tn__=-R"><p class="video-player"><i class="si-caret-right"></i> เบื้องหลังการคัดเลือก</p></a>

        <video autoplay muted loop>
        <source src="/bg.mp4" type="video/mp4">
        </video>



      </div>

      <div class="video-embed">
    <iframe src="https://web.facebook.com/plugins/video.php?href=https%3A%2F%2Fweb.facebook.com%2Frainmakerth%2Fvideos%2F1384417541731208%2F&show_text=0&width=560" width="100%" height="100%" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
    </div>
    </header>

    <div class="creator-list">



    <h1 class="influencer-category">Newcomer</h1>
    <creator-item v-for="item in newcommers" :data="item"></creator-item>

      <h1 class="influencer-category">Medias</h1>
      <creator-item v-for="item in medias" :data="item"></creator-item>


      <h1 class="influencer-category">Lifestyles / Travel / Food</h1>
      <creator-item v-for="item in lifestyles" :data="item"></creator-item>


      <h1 class="influencer-category">Entertainment</h1>
      <creator-item v-for="item in entertainments" :data="item"></creator-item>

      <h1 class="influencer-category">Games / Business / Tech</h1>
      <creator-item v-for="item in games" :data="item"></creator-item>


    <h1 class="influencer-category">Beauty Bloggers</h1>
      <creator-item v-for="item in beauties" :data="item"></creator-item>

      <h1 class="influencer-category">Others</h1>
      <creator-item v-for="item in others" :data="item"></creator-item>


      <footer>
        <p>COPYRIGHT © 2019 The Zero Publishing. ALL RIGHTS RESERVED.</p>
      </footer>
    </div>
  </div>
</template>

<script>
  import CreatorItem from './CreatorItem'

  export default {

    computed: {
      publishers() {
        return this.store.creators.filter(c => c.category === 'publisher')
      },

      lifestyles() {
        return this.store.creators.filter(c => c.category === 'Lifestyletravelfood')
        },

    medias() {
      return this.store.creators.filter(c => c.category === 'Media')
      },

  newcommers() {
    return this.store.creators.filter(c => c.category === 'Newcommer')
    },

games() {
  return this.store.creators.filter(c => c.category === 'GamesGadgetBusiness')
  },

beauties() {
  return this.store.creators.filter(c => c.category === 'Beauty')
  },

others() {
  return this.store.creators.filter(c => c.category === 'Other')
  },

entertainments() {
  return this.store.creators.filter(c => c.category === 'Entertainment')
  }
  },

    components: {CreatorItem}
  }
</script>
