<template>
  <router-link :to="link">
    <div class="creator">
      <div class="creator-image" :style="image"></div>

      <div class="creator-info">
        <h1 class="creator-name">{{data.name}}</h1>
        <h2 class="creator-minor-name">{{data.subname}}</h2>
      </div>
    </div>
  </router-link>
</template>

<script>
  export default {
    props: ["data"],

    computed: {
      link() {
        return `${this.data.category}/${this.data.name}`
      },

      image() {
        return {
          'background-image': `url(${this.data.image})`
        }
      }
    }
  }
</script>